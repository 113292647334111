.headerLink {
  transition: all 0.3s ease-in-out;
  font-size: 2em;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.8em 0 0.8em 1em;
  color: #00456a;
}

.headerLink:hover {
  background: #0d74af;
  color: #fff;
}

@media (min-width: 992px) {
  .headerLink {
    text-transform: none;
    padding: 0;
    padding-bottom: 0.2em;
  }

  .headerLink:hover {
    padding: 0;
    background: none;
    box-shadow: 0 4px 0 0 #00669d;
    color: #00669d;
  }
}
