#toggle-icon-svg #line_1,
#toggle-icon-svg #line_2,
#toggle-icon-svg #line_3 {
  transition: all 0.3s ease-in-out;
  transition: all 0.2s linear;
}

.toggle-icon {
  -webkit-transform: rotate(90deg) translate(0px, -36px);
  transform: rotate(90deg) translate(0px, -36px);
  transition: all 0.3s ease-in-out;
}

.anchor {
  display: block;
  height: 61.56px;
  margin-top: -61.56px;
  visibility: hidden;
}
@media (min-width: 767px) {
  .anchor {
    height: 0px;
    margin-top: 0px;
  }
}

.menu {
  position: fixed;
  z-index: 100;
  background: rgba(255, 255, 255, 0.97);
  width: 260px;
  height: calc(100vh - 7.2em);
  left: 0;
  top: 0;
  box-shadow: 1px 0 1px 1px rgba(0, 0, 0, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 7.2em;
}
@media (min-width: 992px) {
  .menu {
    position: static;
    width: 500px;
    background: none;
    height: auto;
    box-shadow: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding: 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .menu {
    width: 600px;
  }
}

@media (min-width: 992px) {
  nav {
    -ms-flex-preferred-size: 400px;
    flex-basis: 400px;
  }
}

#toggleInput {
  display: none;
}

.toggle-label {
  z-index: 200;
  position: relative;
  cursor: pointer;
}

label.main-nav.toggle-label:hover #line_1,
label.main-nav.toggle-label:hover #line_2,
label.main-nav.toggle-label:hover #line_3 {
  fill: #0d74af;
}

.menu.activated {
  transition: all 0.3s ease-in-out;
}

.control ~ .menu,
.control ~ .menu.active {
  -webkit-transform: translateX(-400px);
  transform: translateX(-400px);
}
@media (min-width: 992px) {
  .control ~ .menu,
  .control ~ .menu.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.control:checked ~ label.main-nav.toggle-label svg.toggle-icon-svg #line_1 {
  -webkit-transform: rotate(45deg) translate(21px, -18px);
  transform: rotate(45deg) translate(21px, -18px);
}

.control:checked ~ label.main-nav.toggle-label svg.toggle-icon-svg #line_2 {
  opacity: 0;
  -webkit-transform: translate(0, 36px);
  transform: translate(0, 36px);
}

.control:checked ~ label.main-nav.toggle-label svg.toggle-icon-svg #line_3 {
  -webkit-transform: rotate(-45deg) translate(-28px, 7px);
  transform: rotate(-45deg) translate(-28px, 7px);
}

.control:checked ~ .menu,
.control:checked ~ .menu.active {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
@media (min-width: 992px) {
  .control:checked ~ .menu,
  .control:checked ~ .menu.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media (min-width: 992px) {
  .control:checked ~ label,
  .control ~ label {
    display: none;
  }
  .control:checked ~ .menu.active,
  .control ~ .menu.active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  .control:checked ~ .menu.activated,
  .control ~ .menu.activated {
    transition: all 0.3s ease-in-out;
  }
  .control:checked ~ svg.toggle-icon-svg #line_1,
  .control ~ svg.toggle-icon-svg #line_1 {
    -webkit-transform: rotate(45deg) translate(14px, -18px);
    transform: rotate(45deg) translate(14px, -18px);
    x: 13;
    y: 0;
  }
  .control:checked ~ svg.toggle-icon-svg #line_2,
  .control ~ svg.toggle-icon-svg #line_2 {
    opacity: 0;
    -webkit-transform: translate(0, 36px);
    transform: translate(0, 36px);
  }
  .control:checked ~ svg.toggle-icon-svg #line_3,
  .control ~ svg.toggle-icon-svg #line_3 {
    -webkit-transform: rotate(-45deg) translate(-17px, 20px);
    transform: rotate(-45deg) translate(-17px, 20px);
    x: 13.9999027;
    y: -9.75091334;
  }
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.125);
  transition: all 0.3s ease-in-out;
}
@media (min-width: 992px) {
  header {
    position: relative;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.125);
  }
}

header .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.capital-one-logo {
  -ms-flex-preferred-size: 100px;
  flex-basis: 100px;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.headroom-wrapper {
  height: 57px;
}

section {
  overflow: hidden;
}

section .inner,
header .inner,
footer .inner {
  padding: 0 2.6em;
}
@media (min-width: 992px) {
  section .inner,
  header .inner,
  footer .inner {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .inner.pad-left {
    padding-left: 4em;
  }
}

@media (min-width: 992px) {
  .inner.pad-right {
    padding-right: 4em;
  }
}

.primary-content {
  position: relative;
}
@media (min-width: 992px) {
  .primary-content {
    width: 70em;
    margin: 0 auto;
  }
}
@media (min-width: 992px) {
  .primary-content {
    width: 90em;
  }
}
@media (min-width: 1200px) {
  .primary-content {
    width: 110em;
  }
}
@media (min-width: 1600px) {
  .primary-content {
    width: 120em;
  }
}
