.container-space{
  background-color: #011828;
  display: flex;
}

.wrapper-space{
  padding: 4em 0;
}

.primary-space{
  flex-basis: 0%;
}
.secondary-space {
  flex-basis: 100%;
}
@media (min-width: 992px) {
  .primary-space,
  .secondary-space {
    flex-basis: 50%;
    margin: 0;
    margin: 0 auto;
    position: relative;
  }
}
.split-container {
  box-sizing: border-box;
}

.pad-left-space {
  padding-left: 0em;
}

.pad-right-space {
  padding-right: 0em;
}
@media (min-width: 992px) {
  .pad-right-space {
    padding-right: 4em;
  }
}

.space-img {
  margin-top: 60px;
  margin-left: 60px;
  height: 85%;
  width: 85%;
}

.body-space {
  margin-top: 0;
  font-size: 1.6em;
  font-weight: 200;
  line-height: 1.7em;
  margin-bottom: 4em;
  margin-left: -1em;
  margin-right: 2em;
  text-align:center;
}
@media (min-width: 767px) {
  .body-space {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.7em;
    margin-bottom: 3em;
    padding-bottom: 3em;
    margin-left: 1.5em;
    text-align:center;
    margin-right: 3em;
  }
}
@media (min-width: 992px) {
  .body-space {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.7em;
    margin-bottom: 3em;
    padding-bottom: 3em;
    margin-left: 1em;
    margin-right: 3em;
    text-align:left;
  }
}
@media (min-width: 1200px) {
  .body-space {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.7em;
    margin-bottom: 3em;
    padding-bottom: 3em;
    margin-left: 1em;
    margin-right: 4em;
    text-align:left;
  }
}
@media (min-width: 1200px) {
  .body-space {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.7em;
    margin-bottom: 3em;
    padding-bottom: 3em;
    margin-left: 1em;
    margin-right: 6em;
    text-align:left;
  }
}
@media (min-width: 1600px) {
  .body-space {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.75em;
    margin-bottom: 3em;
    padding-bottom: 3em;
    margin-left: 1em;
    margin-right: 300px;
    text-align:left;
  }
}

.subheading-space {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.6em;
  padding-top: 2em;
  margin-bottom: 0;
  text-align: center;
  margin-left: -3em;
}
@media (min-width: 767px) {
  .subheading-space {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    padding-top: 1em;
    margin-bottom: 0;
    text-align: center;
    margin-left: 0px;
  }
}
@media (min-width: 992px) {
  .subheading-space {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 2em;
    padding-top: 3em;
    margin-bottom: 0;
    text-align: left;
    margin-left: 1em;
  }
}

.space-bg {
  margin-top: 4em;
  margin-left: 4em;
  margin-bottom: 4em;
  padding-bottom: 2em;
  background-image: url(../../assets/img/circle-image.png);
  background-repeat: no-repeat;
  width: 85%;
  height: 85%;
  background-size: contain;
  background-position: center;
}
@media screen and (max-width: 992px) {
  .space-bg {
    background-size:0;
    background-position:none;
  }
}

.mobile-only {
  margin-left:-4em;
  padding-top: 10em;
  height: 70%;
  width: 70%;
}
@media screen and (min-width: 992px) {
  .mobile-only {
    display: none;
  }
}