.primary-content {
  position: relative;
}
@media (min-width: 767px) {
  .primary-content {
    width: 70em;
    margin: 0 auto;
  }
}
@media (min-width: 992px) {
  .primary-content {
    width: 90em;
  }
}
@media (min-width: 1200px) {
  .primary-content {
    width: 110em;
  }
}
@media (min-width: 1600px) {
  .primary-content {
    width: 120em;
  }
}

footer .primary-content {
  padding: 5em 0;
}

.bg-color.white {
  background: #fff;
  color: #000; 
}

.center {
  text-align: center;
  margin: 0 auto; 
}

footer .inner {
  padding: 0 2.6em; 
}
@media (min-width: 992px) {
  footer .inner {
    padding: 0; 
  } 
}

.body-copy.small {
  font-size: 1.4em; 
}
@media (min-width: 767px) {
  .body-copy.small {
    font-size: 1.6em; 
  } 
}

.body-copy.no-margin-bottom {
  margin-bottom: 0em; 
}

.body-copy.small-margin-bottom {
  margin-bottom: .5em; 
}

footer .capital-one-logo {
  width: 15em;
  margin: 0 auto;
  margin-bottom: 2.5em; 
}
@media (min-width: 767px) {
  footer .capital-one-logo {
    width: 20em; 
  } 
}

footer {
    display: block;
}

.emailLink {
  color: #0D74AF;
  text-decoration: none;
  transition: all 0.3s ease-in-out; 
}
.emailLink:hover {
  color: #1193de;
}

.divider {
  background-color: #021728;
  height: 5px;
}