.core{
  font-family: sans-serif;
}

.container{
  background-image: url(../../assets/img/hero-banner-2x-overlay.jpg);
  background-position: center;
  background-size: cover;
}
@media (min-width: 767px) {
  .container{
    background-image: url(../../assets/img/hero-banner-2x-overlay.jpg);
    background-position: center;
    background-size: cover;
  }
}
.hero-logo {
  width: 8em;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 767px) {
  .hero-logo {
    width: 15em;
    margin-right: auto;
    margin-left: auto;
  }
}

.valign {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.wrapper {
  margin-right: auto; /* 1 */
  margin-left: auto; /* 1 */

  max-width: 960px; /* 2 */
  padding: 4em 0;
  text-align: center;
}
@media (min-width: 767px) {
  .wrapper {
    margin-right: auto; /* 1 */
    margin-left: auto; /* 1 */
  
    max-width: 960px; /* 2 */
    padding: 10em 0;
    text-align: center;
  }
}

.hero-button {
  border: 2px solid white;
  background-color: transparent;
  color: white;
  padding: 14px 22px;
  font-size: 20px;
  border-radius: 7px;
  display: inline-block;
  font-family: inherit;
  font-weight: 500;
  text-decoration: none;
  transition: all 100ms linear;
}

h2 {
  font-size: 1.6em;
  line-height: 2.8em;
  font-weight: 400;
  letter-spacing: .01em;
  margin-bottom: 0em;
  text-align: center;
}
@media (min-width: 767px) {
  h2 {
    font-size: 2.5em;
    text-align: center;
  }
}

h3 {
  font-size: 2.0em;
}

h4 {
  font-size: 1.8em;
}

.center {
  text-align: center;
  margin: 0 auto;
}

.hero-button:hover {
  background-color: #0c74ae;
  border-color: #0c74ae;
}

.heading-hero-title {
  font-size: 6em;
  font-weight: 600;
  line-height: 1em;
  text-align: center;
}
@media (min-width: 767px) {
  .heading-hero-title {
    line-height: 1.2em;
    margin-top: .5em;
    text-align: center;
    margin-bottom: 0em;
    font-size: 6em;
    font-weight: 600;
  }
}

.heading-hero {
  font-size: 1.5em;
  line-height: 1em;
  text-align: center;
}
@media (min-width: 767px) {
  .heading-hero {
    font-weight: 400;
    text-transform: none;
    line-height: 1.2em;
    margin-bottom: 0em;
    margin-top: .5em;
    font-size: 5em;
    text-align: center;
  }
}

.heading-title {
  text-transform: uppercase;
  font-size: 6em;
  font-weight: 600;
  margin-bottom: 0em;
}
@media (min-width: 767px) {
  .heading-title {
    font-size: 6em;
    font-weight: 600;
    margin-bottom: 0em;
  }
}

.body-copy-hero {
  font-size: 1.5em;
  line-height: 1.5em;
}
@media (min-width: 767px) {
  .body-copy-hero {
    font-size: 3.5em;
    line-height: 1.7em;
    font-weight: 400;
    margin-top: .5em;
    margin-bottom: 1em;
    text-align: center;
  }
}