.container-growth{
  background-color: #00456a;
  display: flex;
}

.wrapper-growth {
  padding: 4em 0;
}

.primary-growth{
  flex-basis: 100%;
}
.secondary-growth {
  flex-basis: 0%;
}
@media (min-width: 1000px) {
  .primary-growth,
  .secondary-growth {
    flex-basis: 50%;
    margin: 0;
    margin: 0 auto;
    position: relative;
  }
}

.split-container {
  box-sizing: border-box;
}

.pad-left-growth {
  padding-left: 0em;
}

.pad-right-growth {
  padding-right: 4em;
}

.body-growth {
  margin-top: 0;
  font-size: 1.6em;
  font-weight: 200;
  line-height: 1.7em;
  margin-bottom: 2.5em;
  padding-bottom: 3em;
  margin-left: 2em;
  text-align:center;
}
@media (min-width: 767px) {
  .body-growth {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.7em;
    margin-bottom: 2.5em;
    padding-bottom: 3em;
    text-align:center;
  }
}
@media (min-width: 992px) {
  .body-growth {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.7em;
    margin-bottom: 2.5em;
    padding-bottom: 3em;
    margin-left: 3em;
    text-align:left;
    margin-right: 0px;
  }
}
@media (min-width: 1200px) {
  .body-growth {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.7em;
    margin-bottom: 2.5em;
    padding-bottom: 3em;
    margin-left: 4em;
    text-align:left;
    margin-right: 0px;
  }
}
@media (min-width: 1600px) {
  .body-growth {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.7em;
    margin-bottom: 2.5em;
    padding-bottom: 3em;
    margin-left: 12em;
    text-align:left;
    margin-right: 0px;
  }
}

.subheading-growth {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.6em;
  line-height: 2.8em;
  letter-spacing: .01em;
  margin-top: 2em;
  padding-top: 3em;
  margin-bottom: 0;
  text-align: center;
  margin-left:3em;
}
@media (min-width: 767px) {
  .subheading-growth {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 2em;
    padding-top: 3em;
    margin-bottom: 0;
    text-align: center;
    margin-left:3em;
  }
}
@media (min-width: 1000px) {
  .subheading-growth {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 2em;
    padding-top: 3em;
    margin-bottom: 0;
    text-align: left;
    margin-left: 3em;
  }
}
@media (min-width: 1200px) {
  .subheading-growth {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 2em;
    padding-top: 3em;
    margin-bottom: 0;
    text-align: left;
    margin-left: 4em;
  }
}
@media (min-width: 1600px) {
  .subheading-growth {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 2em;
    padding-top: 3em;
    margin-bottom: 0;
    text-align: left;
    margin-left: 12em;
  }
}

.growth-bg {
  background-image: url(../../assets/img/powerful-growth.jpg);
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}