.row {
  display: flex;
}

.column {
  flex: 33.33%;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.container-startup{
  background-color: #ffffff;
  display: flex;
}

.wrapper-startup {
  padding: 4em 0;
}

.anchor{
  position: absolute;
  top: -5em;
  visibility: hidden;
}
@media (min-width: 992px) {
  .anchor{
    position: absolute;
    top: 0em;
    visibility: hidden;
  }
}

.primary-startup{
  flex-basis: 100%;
  position: absolute;
}
.secondary-startup {
  flex-basis: 100%;
  margin-top: 40em;
  position: relative;
  background-color: #00456a;
}
@media (min-width: 767px) {
  .primary-startup{
    flex-basis: 100%;
    position: absolute;
  }
  .secondary-startup {
    flex-basis: 100%;
    margin-top: 75em;
    position: relative;
    background-color: #00456a;
  }
}
@media (min-width: 992px) {
  .primary-startup,
  .secondary-startup {
    display: inline;
    flex-basis: 50%;
    margin: 0;
    margin: 0 auto;
    position: relative;
  }
}

.split-container {
  box-sizing: border-box;
}

.pad-left-start {
  padding-left: 0em;
}

.pad-right-start {
  padding-right: 0em;
}
@media (min-width: 992px) {
  .pad-right-start {
    padding-right: 4em;
  }
}

.subheading-startup {
  padding-top: 1em;
  color:black;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.6em;
  margin-top: 2em;
  margin-bottom: 0;
  text-align: center;
  margin-left: 0em;
}
@media (min-width: 767px) {
  .subheading-startup {
    padding-top: 3em;
    color:black;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 2.5em;
    margin-bottom: 0;
    text-align: center;
  }
}
@media (min-width: 992px) {
  .subheading-startup {
    padding-top: 3em;
    color:black;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 0em;
    margin-bottom: 0;
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .subheading-startup {
    padding-top: 3em;
    color:black;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 0em;
    margin-bottom: 0;
    text-align: center;
  }
}
/* @media (min-width: 1600px) {
  .subheading-startup {
    padding-top: 3em;
    color:black;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 2.5em;
    margin-bottom: 0;
    text-align: left;
    margin-left: 12em;
  }
} */
@media (min-width: 1600px) {
  .subheading-startup {
    padding-top: 3em;
    color:black;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 2.5em;
    margin-bottom: 0;
    text-align: center;
    margin-left: 0;
  }
}

.body-startup {
  color:black;
  margin-top: 0;
  font-size: 1.6em;
  font-weight: 200;
  line-height: 1.75em;
  text-align:center;
  margin-left: 2em;
  margin-right:2em;
  margin-bottom: 1em;
}
@media (min-width: 767px) {
  .body-startup {
    color:black;
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.7em;
    margin-left:2em;
    margin-right:2em;
    text-align:center;
    margin-bottom: 1em;
  }
}
@media (min-width: 992px) {
  .body-startup {
    color:black;
    margin-top: 0;
    text-align:center;
    margin-left: 2em;
    margin-right:2em;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.7em;
    margin-bottom: 1em;
  }
}
@media (min-width: 1200px) {
  .body-startup {
    color:black;
    margin-top: 0;
    text-align:center;
    margin-left: 2em;
    margin-right:2em;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.75em;
    margin-bottom: 1em;
  }
}
@media (min-width: 1400px) {
  .body-startup {
    color:black;
    margin-top: 0;
    text-align:center;
    margin-left: 4em;
    margin-right:4em;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.75em;
    margin-bottom: 1em;
  }
}
@media (min-width: 1600px) {
  .body-startup {
    color:black;
    margin-top: 0;
    text-align:center;
    margin-left: 4em;
    margin-right:4em;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.75em;
    margin-bottom: 1em;
  }
}

.img-group-1 {
  padding-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  margin-bottom: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 767px) {
  .img-group-1 {
    padding-top: 2em;
    padding-left: 7.5em;
    padding-right: 7.5em;
    margin-bottom: 6em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .img-group-1 {
    padding-top: 2em;
    padding-left: 8em;
    padding-right: 8em;
    margin-bottom: 6em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .img-group-1 {
    padding-top: 2em;
    padding-left: 5em;
    padding-right: 5em;
    margin-bottom: 6em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 1400px) {
  .img-group-1 {
    padding-top: 2em;
    padding-left: 1em;
    margin-left:8em;
    padding-right: 2em;
    margin-bottom: 6em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 1600px) {
  .img-group-1 {
    padding-top: 2em;
    padding-left: 1.5em;
    margin-left: 8em;
    padding-right: 4em;
    margin-bottom: 6em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.start-logo {
  flex-basis: 33%;
  height:50%;
  width:50%;
}
@media (min-width: 767px) {
  .start-logo {
    flex-basis: 33%;
    height:50%;
    width:50%;
  }
}
@media (min-width: 992px) {
  .start-logo {
    flex-basis: 33%;
    height:60%;
    width:60%;
  }
}
@media (min-width: 1200px) {
  .start-logo {
    flex-basis: 33%;
    height:60%;
    width:60%;
  }
}
@media (min-width: 1400px) {
  .start-logo {
    flex-basis: 33%;
    height:60%;
    width:60%;
  }
}
@media (min-width: 1600px) {
  .start-logo {
    flex-basis: 33%;
    height:50%;
    width:50%;
  }
}

.mbl-logo {
  flex-basis: 33%;
  height:25%;
  width:25%;
}
@media (min-width: 767px) {
  .mbl-logo {
    flex-basis: 33%;
    height:25%;
    width:25%;
  }
}
@media (min-width: 992px) {
  .mbl-logo {
    flex-basis: 33%;
    height:25%;
    width:25%;
  }
}
@media (min-width: 1200px) {
  .mbl-logo {
    flex-basis: 33%;
    height:35%;
    width:35%;
  }
}
@media (min-width: 1400px) {
  .mbl-logo {
    flex-basis: 33%;
    height:35%;
    width:35%;
  }
}
@media (min-width: 1600px) {
  .mbl-logo {
    flex-basis: 33%;
    height:25%;
    width:25%;
  }
}

.bridge-logo {
  flex-basis: 33%;
  height:50%;
  width:50%;
}
@media (min-width: 767px) {
  .bridge-logo {
    flex-basis: 33%;
    height:50%;
    width:50%;
  }
}
@media (min-width: 992px) {
  .bridge-logo {
    flex-basis: 33%;
    height:60%;
    width:60%;
  }
}
@media (min-width: 1200px) {
  .bridge-logo {
    flex-basis: 33%;
    height:60%;
    width:60%;
  }
}
@media (min-width: 1400px) {
  .bridge-logo {
    flex-basis: 33%;
    height:60%;
    width:60%;
  }
}
@media (min-width: 1600px) {
  .bridge-logo {
    flex-basis: 33%;
    height:50%;
    width:50%;
  }
}

.subheading-light {
  padding-top: 1em;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.6em;
  margin-top: 2em;
  margin-bottom: 0;
  text-align: center;
}
@media (min-width: 767px) {
  .subheading-light {
    padding-top: 3em;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 2.5em;
    margin-bottom: 0;
    text-align: center;
  }
}
@media (min-width: 992px) {
  .subheading-light {
    padding-top: 3em;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 0em;
    margin-bottom: 0;
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .subheading-light {
    padding-top: 3em;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 0em;
    margin-bottom: 0;
    text-align:center;
    margin-left: 4em;
    margin-right:4em;
  }
}
/* @media (min-width: 1600px) {
  .subheading-light {
    padding-top: 3em;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 2.5em;
    margin-bottom: 0;
    text-align: left;
    margin-left: 1em;
  }
} */
@media (min-width: 1600px) {
  .subheading-light {
    padding-top: 3em;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 2.5em;
    margin-bottom: 0;
    text-align:center;
    margin-left: 4em;
    margin-right:4em;
  }
}
.body-light {
  margin-top: 0;
  font-size: 1.6em;
  font-weight: 200;
  line-height: 1.75em;
  text-align:center;
  margin-left: 2em;
  margin-right:2em;
  margin-bottom: 1em;
}
@media (min-width: 767px) {
  .body-light {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.7em;
    margin-left:2em;
    margin-right:2em;
    text-align:center;
    margin-bottom: 1em;
  }
}
@media (min-width: 992px) {
  .body-light {
    margin-top: 0;
    text-align:center;
    margin-left: 2em;
    margin-right:2em;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.7em;
    margin-bottom: 1em;
  }
}
@media (min-width: 1200px) {
  .body-light {
    margin-top: 0;
    text-align:center;
    margin-left: 2em;
    margin-right:2em;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.75em;
    margin-bottom: 1em;
  }
}
@media (min-width: 1400px) {
  .body-light {
    margin-top: 0;
    text-align:center;
    margin-left: 4em;
    margin-right:4em;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.75em;
    margin-bottom: 1em;
  }
}
/* @media (min-width: 1600px) {
  .body-light {
    margin-top: 0;
    margin-right: 300px; 
    margin-left: 1em; 
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.75em;
    text-align:left;
    margin-bottom: 1em;
  }
} */
@media (min-width: 1600px) {
  .body-light {
    margin-top: 0;
    text-align:center;
    margin-left: 4em;
    margin-right:4em;
    font-size: 2.5em;
    font-weight: 200;
    line-height: 1.75em;
    margin-bottom: 1em;
  }
}

.light-logo {
  margin-top: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  padding-bottom: 2em;
  height:30%;
  width:30%;
}
@media (min-width: 767px) {
  .light-logo {
    margin-top: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6em;
    padding-bottom: 3em;
    height:30%;
    width:30%;
  }
}
@media (min-width: 992px) {
  .light-logo {
    margin-top: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6em;
    padding-bottom: 3em;
    height:40%;
    width:40%;
  }
}
@media (min-width: 1200px) {
  .light-logo {
    margin-top: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6em;
    padding-bottom: 3em;
    height:40%;
    width:40%;
  }
}
@media (min-width: 1400px) {
  .light-logo {
    margin-top: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6em;
    padding-bottom: 3em;
    height:30%;
    width:30%;
  }
}
/* @media (min-width: 1600px) {
  .light-logo {
    margin-top: 0px;
    margin-left: 0em;
    margin-right: 0em;
    margin-bottom: 6em;
    padding-bottom: 3em;
    height:30%;
    width:30%;
  }
} */
@media (min-width: 1600px) {
  .light-logo {
    margin-top: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-inline-size: 40em;
    margin-bottom: 6em;
    padding-bottom: 3em;
    height:30%;
    width:30%;
  }
}


/* .start-bg {
  background-image: url(../../assets/img/startup-virginia.jpg);
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.mobile-only-startup {
  display: block;
  padding-top: 0em;
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .mobile-only-startup {
    display: none;
  }
} */