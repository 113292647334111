.container-overview{
  background-color: #0c74ae;
}

.wrapper-overview {
  margin-right: auto; /* 1 */
  margin-left: auto; /* 1 */

  max-width: 800px; /* 2 */
  padding: 4em 0;
}
@media (min-width: 767px) {
  .wrapper-overview {
    margin-right: auto; /* 1 */
    margin-left: auto; /* 1 */
  
    max-width: 1200px; /* 2 */
    padding: 4em 0;
  }
}
.subheading-overview {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.6em;
  line-height: 2.8em;
  letter-spacing: .01em;
  margin-bottom: 0;
  margin-top: 0;
}
@media (min-width: 767px) {
  .subheading-overview {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.5em;
    margin-top: 2.5em;
    margin-bottom: 0;
  }
}
.body-copy.overview {
  margin-top: 0;
  margin-right: 2em;
  margin-left: 2em;
  font-size: 1.6em;
  font-weight: 300;
  line-height: 1.7em;
  margin-bottom: 3em;
  text-align:center;
}
@media (min-width: 767px) {
  .body-copy.overview {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 300;
    line-height: 1.7em;
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 3em;
    text-align:center;
  }
}
@media (min-width: 992px) {
  .body-copy.overview {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 300;
    line-height: 1.7em;
    margin-left: 2em;
    margin-bottom: 3em;
    text-align:left;
  }
}
@media (min-width: 1200px) {
  .body-copy.overview {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 300;
    line-height: 1.7em;
    margin-left: 2em;
    margin-bottom: 3em;
    text-align:left;
  }
}
@media (min-width: 1600px) {
  .body-copy.overview {
    margin-top: 0;
    font-size: 2.5em;
    font-weight: 300;
    line-height: 1.7em;
    margin-left: 0em;
    margin-bottom: 3em;
    text-align:left;
  }
}