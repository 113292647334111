html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: Optimist, sans-serif;
  font-size: 62.5%;
  color: #fff;
  margin: 0;
}

img {
  display: block;
}

a {
  background: transparent;
  -webkit-text-decoration-skip: objects;
}

p, ul {
  font-size: 1.6em;
  line-height: 1.7em;
  font-weight: 300;
  margin-top: 0em; 
}
@media (min-width: 767px) {
  p, ul {
    font-size: 2.5em; 
  } 
}